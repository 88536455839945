<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="success"
      @click="toggleModal()"
    >
      <feather-icon
        icon="PlusIcon"
      />
      {{ $t('buttons.add') }}
    </b-button>
    <b-modal
      v-model="isModalActive"
      title="เพิ่ม การแจ้งเตือนไลน์"
      hide-footer
    >
      <form @submit.prevent="onSubmit">
        <agent-select-input
          label-cols="3"
          @update="(agentId) => lineForm.agentId = agentId"
        />
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label="เลือกประเภท"
          label-for="type"
        >
          <b-select
            id="type"
            v-model="lineForm.type"
          >
            <b-form-select-option
              v-for="(type, typeI) in typeOptions"
              :key="typeI"
              :value="type.value"
            >
              {{ type.name }}
            </b-form-select-option>
          </b-select>
        </b-form-group>
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label="ชื่อ"
          label-for="name"
        >
          <b-input
            id="name"
            v-model="lineForm.name"
          />
        </b-form-group>
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label="Token"
          label-for="token"
        >
          <b-input
            id="token"
            v-model="lineForm.token"
          />
        </b-form-group>

        <div class="text-right">
          <b-button
            variant="light"
            class="mr-2"
            @click="onCloseModal"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-overlay
            :show="isLoading"
            rounded
            spinner-small
            class="d-inline-block"
          >
            <b-button
              type="submit"
              variant="primary"
              block
            >
              {{ $t('buttons.confirm') }}
            </b-button>
          </b-overlay>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'LineNotificationForm',
  props: {
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalActive: false,
      typeOptions: [
          // 0 = ฝาก
          // 1 = แจ้งถอน
          // 2 = OTP ถอน
        { value: 0, name: 'ฝาก'},
        { value: 1, name: 'ถอน'},
        { value: 2, name: 'OTP ถอน'},
      ],
      lineForm: {
        agentId: '',
        type: 0,
        name: '',
        token: '',
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.line.isCreatingLineNotification,
    }),
  },
  created() {
    this.lineForm = {
      agentId: '',
      type: 0,
      name: '',
      token: '',
    }
  },
  methods: {
    ...mapActions(['createLineNotification']),
    toggleModal() {
      this.isModalActive = !this.isModalActive
    },
    onCloseModal() {
      this.isModalActive = false
    },
    async onSubmit() {
      await this.createLineNotification(this.lineForm)
      this.onCloseModal()
      this.lineForm = {
        agentId: '',
        type: 0,
        name: '',
        token: '',
      }
    },
  },
}
</script>
